
import { defineComponent } from "vue";
import {
  DataLine,
  Setting,
  Menu,
  Operation,
  CreditCard,
} from "@element-plus/icons-vue";
export default defineComponent({
  components: {
    DataLine,
    Setting,
    Menu,
    Operation,
    CreditCard,
  },
  data() {
    return {
      active: "",
    };
  },
  props: {
    collapse: {
      type: Boolean,
      default: false, // 默认不折叠
    },
  },

  computed: {
    isCollapse() {
      return this.collapse;
    },
    toggleButton() {
      if (this.isCollapse) {
        return "minMargin";
      } else {
        return "maxMargin";
      }
    },
  },
  mounted() {
    this.active = `/${this.$route.path.split("/")[1]}`;
  },
  methods: {
    handleOpen(key: any, keyPath: any) {
      console.log(key, keyPath);
    },
    handleClose(key: any, keyPath: any) {
      console.log(key, keyPath);
    },
    togleCollapse() {
      this.isCollapse = !this.isCollapse;
    },
  },
});
