import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "crumbs" }
const _hoisted_2 = { href: "javascript:;" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_breadcrumb_item = _resolveComponent("el-breadcrumb-item")!
  const _component_el_breadcrumb = _resolveComponent("el-breadcrumb")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_breadcrumb, { separator: "/" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_breadcrumb_item, null, {
          default: _withCtx(() => [
            _createElementVNode("a", _hoisted_2, _toDisplayString(_ctx.$route.matched[0].meta.title), 1)
          ]),
          _: 1
        }),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.levelList, (level, index) => {
          return (_openBlock(), _createBlock(_component_el_breadcrumb_item, {
            key: index,
            to: level.path
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(level.name), 1)
            ]),
            _: 2
          }, 1032, ["to"]))
        }), 128))
      ]),
      _: 1
    })
  ]))
}