
import { Delete, Expand, Fold, SwitchButton } from "@element-plus/icons-vue";
import asideMenu from "../../components/menu.vue";
import { getCookie, setCookie } from "../../utils/tools";

import crumbs from "../../components/crumbs.vue";
import { defineComponent } from 'vue';

export default defineComponent({
  name: "home-index",
  components: {
    asideMenu,
    Expand,
    Fold,
    SwitchButton,
    crumbs,
  },
  setup() {
    return {
      Expand,
      Fold,
      Delete,
    };
  },
  data: () => {
    return {
      isCollapse: false,
      username: "",
    };
  },
  computed: {},
  watch: {
    $route(to) {
      to.query && to.query.transitionName;
    },
  },
  created() {
    this.queryUserInfo();
  },
  methods: {
    togleCollapse() {
      this.isCollapse = !this.isCollapse;
    },
    queryUserInfo() {
      this.username = getCookie("username")||"";
    },

    quit() {
      setCookie("token", "");
      this.$router.push({ name: "login-in" });
    },
  },
});
